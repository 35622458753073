#botanicus-values {
	margin: 0 auto;
	max-width: 80dvw;
}

.botanicus-values {
	display: grid;
	grid-gap: .5rem 10px;
	grid-template-columns: [row-start] 3fr [row-end side-img-start] minmax(100px, 1fr) [side-img-end];
	grid-template-rows: [side-img-start] 1fr 1fr 1fr 1fr [side-img-end];

	@media screen and (max-width: 600px) {
		grid-template-columns: [row-start side-img-start] 1fr [row-end side-img-end];
		grid-template-rows: [side-img-start] 1fr [side-img-end] 1fr 1fr 1fr 1fr;
	}

	.botanicus-value-row {
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		display: grid;
		grid-column: row;
		grid-template-columns: 4.5rem 1fr;
		grid-gap: 1rem;

		img {
			width: 100%;
		}
	}

	.botanicus-values-side-image {
		align-self: center;
		grid-area: side-img;
		justify-self: center;
		max-width: 200px;
		width: 100%;
	}
}